import Axios, { AxiosResponse, Method } from 'axios'
import { GetTokenSilentlyOptions } from '@auth0/auth0-react'
import { SNACKBAR_CONTENTS } from 'lib/snackbarContents'
import {
  ApiResponse,
  DeletePostOptions,
  DuplicatePostOptions,
} from 'lib/types/api'

import { API_URL, HTTP_STATUS } from 'lib/config'

export const apiClient = Axios.create({ withCredentials: true })

let apiClientTokenInterceptor: any = null

export async function fetcher<DataType = any>(
  url: string,
  useRawUrl?: boolean
): Promise<ApiResponse<DataType>> {
  return await apiClient({
    url,
    method: 'get',
    baseURL: useRawUrl ? API_URL : `${API_URL}/retailers/`,
  })
}

export function addAccessTokenInterceptor(
  getAccessTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string>
) {
  if (apiClientTokenInterceptor != null && apiClientTokenInterceptor >= 0) {
    apiClient.interceptors.request.eject(apiClientTokenInterceptor)
  }

  apiClientTokenInterceptor = apiClient.interceptors.request.use(
    async config => {
      try {
        const accessToken = await getAccessTokenSilently({
          cacheMode: 'on',
        })
        config.headers.Authorization = `Bearer ${accessToken}`
        return config
      } catch (err) {
        console.log(err)
        return config
      }
    }
  )
}

export async function postAPI<T>(
  url: string,
  body = {},
  method: Method = 'post',
  useRawUrl?: boolean
): Promise<AxiosResponse<T>> {
  return await apiClient({
    url,
    method,
    baseURL: useRawUrl ? API_URL : `${API_URL}/retailers/`,
    data: body,
  })
}

const getDeleteMode = (baseUrl: string, unPublishOnly: boolean) => {
  const deleteMode = (unPublishOnly ? 'patch' : 'delete') as Method
  const url = unPublishOnly ? `${baseUrl}/unpublish` : baseUrl

  const successContent = unPublishOnly
    ? SNACKBAR_CONTENTS.POST_UNPUBLISH_SUCCESS
    : SNACKBAR_CONTENTS.POST_DELETE_SUCCESS

  const failureContent = unPublishOnly
    ? SNACKBAR_CONTENTS.POST_UNPUBLISH_ERROR
    : SNACKBAR_CONTENTS.POST_DELETE_ERROR
  return { url, deleteMode, successContent, failureContent }
}

export const deletePost = async (
  {
    postId,
    formType,
    retailerId,
    openSnackbar,
    unpublishOnly = false,
  }: DeletePostOptions,
  cb: () => void
) => {
  const { url, deleteMode, successContent, failureContent } = getDeleteMode(
    `${retailerId}/${formType}/${postId}`,
    unpublishOnly
  )

  try {
    const res = await postAPI<{ success: string }>(url, postId, deleteMode)

    if (res?.data?.success || (res?.data && unpublishOnly)) {
      openSnackbar(successContent)
      cb()
    } else {
      openSnackbar(failureContent)
    }
  } catch (err) {
    openSnackbar(failureContent)
  }

  return null
}

export const duplicatePost = async (
  { postId, formType, retailerId, openSnackbar }: DuplicatePostOptions,
  cb: () => void
) => {
  try {
    const res = await postAPI(`${retailerId}/${formType}/${postId}/duplicate`)

    if (res?.status === HTTP_STATUS.OK) {
      cb()
      openSnackbar(SNACKBAR_CONTENTS.POST_DUPLICATE_SUCCESS)
    } else {
      openSnackbar(SNACKBAR_CONTENTS.POST_DUPLICATE_ERROR)
    }
  } catch (err) {
    openSnackbar(SNACKBAR_CONTENTS.POST_DUPLICATE_ERROR)
  }

  return null
}
